.ct-bar {
    stroke-linecap: round;
}
.ct-label {
    fill: $body-color;
    color: $body-color;
    font-size: 0.75rem;
    margin-top: 6px;
    font-weight: $font-weight-bold;
}

.ct-slice-pie {
    stroke: $white;
    stroke-width: 3px;
}

.ct-chart-donut .ct-series {
    stroke: $white;
    stroke-width: 3px;
}

.ct-chart-pie .ct-label, .ct-chart-donut .ct-label {
    font-size: 0.75rem;
    font-weight: $font-weight-bold;
}

.small-chart{
    margin-left:-1rem;
}

//Label color Fix for pie chart

.ct-chart-pie .ct-label, .ct-chart-donut .ct-label {
    color: #ffffff;
    fill: #ffffff;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
}