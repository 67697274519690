// Global settings
$enable-caret:              true; 
$enable-rounded:            true;
$enable-shadows:            true;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-grid-classes:       true;
$enable-print-styles:       true;

// Color scheme
$default:       #0c3f94;
$primary:       #0948B3;
$secondary:     #EE5050;
$tertiary:      #05A677;
$quaternary:    #fbf6f0;


// Series names and colors. This can be extended or customized as desired. Just add more series and colors.
$ct-series-names: (a, b, c, d, e) !default;
$ct-series-colors: (
  #0948B3,
  #EE5050,
  #05A677,
  #1E90FF,
  #f5b759,

);

// Generic colors
$blue:    #1756c5;
$indigo:  #31316A;
$purple:  #8965e0;
$pink:    #f3a4b5;
$red:     #FA5252;
$orange:  #FF9F89;
$brown:   #b9a084;
$yellow:  #f5b759;
$green:   #05A677;
$teal:    #1E90FF;
$cyan:    #63b1bd;

// Shades of grey
$white:    #ffffff;
$gray-100: #f3f7fa;
$gray-200: #f5f8fb;
$gray-300: #F0F3F6;
$gray-400: #eaedf2;
$gray-500: #b1bcce;
$gray-600: #93a5be;   
$gray-700: #66799e;   
$gray-800: #506690;   
$gray-900: #4A5073;
$dark    : #0d1431; 
$black   : #24262b;

$grays: ();
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
), $grays);

// Main color variables
$success:       $green;
$info:          $teal;
$warning:       $yellow;
$danger:        $red;
$gray:          $gray-900;
$light:         $gray-400;
$soft:          $gray-200;
$dark:          $dark;


// Brands colors
$facebook:      #3b5999;
$twitter:       #1da1f2;
$google:        #dd4b39;
$instagram:     #e4405f;
$pinterest:     #bd081c;
$youtube:       #cd201f;
$slack:         #3aaf85;
$dribbble:      #ea4c89;
$github:        #222222;
$dropbox:       #1E90FF;
$twitch:        #4B367C;
$paypal:        #ecb32c;
$behance:       #0057ff;
$reddit:        #E84422;

// Theme colors
$theme-colors: ();
$theme-colors: map-merge((
  "default":      $default,
  "primary":      $primary,
  "secondary":    $secondary,
  "tertiary":     $tertiary,
  "quaternary":   $quaternary,
  "light":        $light,
  "success":      $success,
  "info":         $info,
  "warning":      $warning,
  "danger":       $danger,
  "white":        $white,
  "gray":         $gray,
  "neutral":      $white,
  "dark":         $dark,
  "soft":         $soft,
  "black":        $black,
  "purple":       $purple,
  "indigo":       $indigo,
  "orange":       $orange,
  "brown":        $brown,
  "blue":         $blue,
  "cyan":         $cyan,
  "gray-100":     $gray-100,
  "gray-200":     $gray-200,
  "gray-300":     $gray-300,
  "gray-400":     $gray-400,
  "gray-500":     $gray-500,
  "gray-600":     $gray-600,
  "gray-700":     $gray-700,
  "gray-800":     $gray-800,
  "facebook":     $facebook,
  "twitter":      $twitter,
  "google":       $google,
  "instagram":    $instagram,
  "pinterest":    $pinterest,
  "youtube":      $youtube,
  "slack":        $slack,
  "dribbble":     $dribbble,
  "dropbox":      $dropbox,
  "twitch":       $twitch,
  "paypal":       $paypal,
  "behance":      $behance,
  "reddit" :      $reddit,  
  "github":       $github
), $theme-colors);

// Brand colors
$brand-colors: ();
$brand-colors: map-merge((
  "facebook":     $facebook,
  "twitter":      $twitter,
  "google":       $google,
  "instagram":    $instagram,
  "pinterest":    $pinterest,
  "youtube":      $youtube,
  "slack":        $slack,
  "dribbble":     $dribbble,
  "dropbox":      $dropbox,
  "twitch":       $twitch,
  "paypal":       $paypal,
  "behance":      $behance,
  "reddit" :      $reddit,  
  "github":       $github
), $brand-colors);


$yiq-contrasted-threshold:  200;
$yiq-text-dark:             $gray-800;

// Image Path
$path--rel      : "/img";

// Body
$body-bg: $white;
$body-color: $gray-900;

// Shadows
$box-shadow-soft:         0 0.75rem 1.5rem rgba(18,38,63,.03);
$box-shadow-sm:           0 2px 18px rgba(0, 0, 0, 0.02);
$box-shadow:              0 1rem 3rem rgba(0,0,0,.175);
$box-shadow-lg:           0 1rem 3rem rgba($dark, .175);
$box-shadow-xl:           0 80px 40px -50px rgb(216, 214, 214);
$box-shadow-around:       -8px 32px 50px rgba(21,33,41,.1);
$shadow-pixel:            .2rem .2rem 0 rgba($gray-200,.5);

// Sections
$section-colors: ();
$section-colors: map-merge((
  "primary":      $primary,
  "secondary":    $secondary,
  "gray":         $gray-400,
  "white":        $white,
  "dark":         $dark,
  "black":        $black 
), $section-colors);

// Links
$link-color:                $primary;
$link-decoration:           none;
$link-hover-color:          darken($link-color, 10%);
$link-hover-decoration:     none;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

// Spacing
$spacer: 1rem;

$spacers: ();
$spacers: map-merge(
  (
    6: ($spacer * 5),
    7: ($spacer * 8),
    8: ($spacer * 10),
    9: ($spacer * 12),
    10: ($spacer * 14),
    11: ($spacer * 16),
    12: ($spacer * 20),
    13: ($spacer * 26),
    'sm': ($spacer * 1),
    'md': ($spacer * 2),
    'lg': ($spacer * 4),
    'xl': ($spacer * 8)
), $spacers);


// This variable affects the `.h-*` and `.w-*` classes.

$sizes: ();
$sizes: map-merge((
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
), $sizes);

// Components

$border-width:                .0625rem;
$border-width-md:             0.125rem;
$border-width-lg:             0.25rem;
$border-width-xl:             0.375rem;
$border-color-white:          $white;
$border-color:                $gray-200;

$border-radius:               .5rem;
$border-radius-xl:            .35rem;
$border-radius-lg:            .3rem;
$border-radius-md:            .2rem;
$border-radius-sm:            .1rem;
$border-radius-pill:           2rem;
$circle-radius:                50%;
$organic-radius:               63% 37% 30% 70% / 50% 45% 55% 50%;

$component-active-color:        $white;
$component-active-bg:           theme-color("primary");
$component-active-border-color: theme-color("primary");

$component-hover-color:        $gray-300;
$component-hover-bg:           $gray-300;
$component-hover-border-color: $gray-300;

$caret-width:                 .3em;

$transition-base:             all .2s ease;
$transition-transform:        transform .2s ease;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;
$transition-tabs:             all 0.2s;


// Fonts
$fontawesome-webfonts-path:   '../vendor/font-awesome/webfonts';
$font-family-base:            'Nunito Sans', sans-serif;
$font-family-headers:         'Nunito Sans', sans-serif;
$font-family-blockquote:      'Lora', serif;
$font-awesome-5:              'Font Awesome 5 Free';
$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-xxl:               ($font-size-base * 2);
$font-size-xl:                ($font-size-base * 1.5);
$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * .875);
$font-size-xs:                ($font-size-base * .75);

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            600;
$font-weight-bolder:          700;
$font-weight-extreme:         800;

$font-weight-base:            $font-weight-light;
$shape-height-base:            1.5;

$h1-font-size:                $font-size-base * 2.5;  //2.1875rem
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$headings-margin-bottom:      ($spacer / 2);
$headings-font-family:        inherit;
$headings-font-weight:        $font-weight-bold;
$headings-color:              $black;
$headings-line-height:        1.3; 

$heading-letter-spacing:      .025em;
$heading-font-size:           .95rem;
$heading-text-transform:      uppercase;
$heading-font-weight:         $headings-font-weight;

$heading-title-letter-spacing:      .025em;
$heading-title-font-size:           1.375rem;
$heading-title-font-weight:         $font-weight-bold;
$heading-title-text-transform:      uppercase;

$heading-section-letter-spacing:      .025em;
$heading-section-font-size:           1.375rem;
$heading-section-font-weight:         $font-weight-bold;
$heading-section-text-transform:      uppercase;

//Large text size

$display-large-text:   $font-size-base * 5.5;  

// Display headings
$display1-size:               4rem;
$display2-size:               3.5rem;
$display3-size:               2.5rem;
$display4-size:               1.875rem  ;

$display1-weight:             $font-weight-bolder;
$display2-weight:             $font-weight-bolder;
$display3-weight:             $font-weight-bolder;
$display4-weight:             $font-weight-bolder;
$display-line-height:         $headings-line-height;

$paragraph-font-size:         1rem;
$paragraph-font-weight:       $font-weight-normal;
$paragraph-line-height:       1.6;

$lead-font-size:              ($paragraph-font-size * 1.25);
$lead-font-weight:            400;

$small-font-size:             80%;

$text-muted:                  $gray-600;

$blockquote-small-color:      $gray-600;
$blockquote-font-size:        ($font-size-base * 1.25);
$blockquote-font-size-lg:     ($font-size-base * 2);

$hr-border-color:             rgba($black, .05);
$hr-border-width:             $border-width;

$mark-padding:                .2em;

$dt-font-weight:              $font-weight-bold;

$list-inline-padding:         .5rem;

$mark-bg:                     #fcf8e3;

$hr-margin-y:                 $spacer * 1.5;

// Breadcrumbs
$breadcrumb-active-color:        $white;
$breadcrumb-divider-color:       $gray-700;
$breadcrumb-item-color:          $gray-700;
$breadcrumb-font-weight:         $font-weight-normal;
$breadcrumb-active-font-weight:  $font-weight-normal;
$breadcrumb-item-light-color:    $white;
$breadcrumb-divider-light-color: $white;

// Icon
$icon-box-padding:      1rem;
$icon-shape:            4.5rem;
$icon-shape-xs:         2rem;
$icon-shape-sm:         3rem;
$icon-shape-lg:         5.5rem;
$icon-shape-img:        5.5rem;
$icon-shape-bordered:   5rem; 
$icon-size:             2rem;
$icon-size-xs:          .875rem; 
$icon-size-sm:          1.25rem;
$icon-size-md:          1.875rem;
$icon-size-lg:          2.75rem;
$icon-size-xl:          4.5rem;

// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 5% !default;

//Owl Carousel

/*
 * 	Default theme - Owl Carousel CSS File
 */

//nav

$owl-nav-color: 			      $gray-400;
$owl-nav-color-hover:	      $gray-700;
$owl-nav-font-size: 		    $font-size-xxl;
$owl-nav-rounded: 			    3px;
$owl-nav-margin: 			      5px;
$owl-nav-padding: 			    4px 7px;
$owl-nav-background: 		    $gray-400;
$owl-nav-background-hover:	$gray-800;
$owl-nav-disabled-opacity: 	0.5;

//Dots

$owl-dot-width:				      10px;
$owl-dot-height:			      10px;
$owl-dot-rounded:			      $circle-radius;
$owl-dot-margin: 			      5px 5px;
$owl-dot-border-width:      2px;
$owl-dot-background:		    $gray-400;
$owl-dot-background-active:	$gray-800;

// Tables

$table-cell-padding:          1rem;
$table-cell-padding-sm:       .3rem;

$table-bg:                    transparent;
$table-accent-bg:             rgba($black, .05);
$table-hover-bg:              rgba($black, .03);
$table-active-bg:             $table-hover-bg;

$table-border-width:          $border-width;
$table-border-color:          $gray-300;

$table-head-bg:               $gray-200;
$table-head-color:            $gray-700;

$table-dark-bg:               $gray-900;
$table-dark-accent-bg:        rgba($white, .05);
$table-dark-hover-bg:         rgba($white, .075);
$table-dark-border-color:     lighten($gray-900, 7.5%);
$table-dark-color:            $body-bg;


// Inputs

$input-btn-padding-y:         .5rem;
$input-btn-padding-x:         1rem;
$input-btn-line-height:       1.5;

$input-btn-focus-width:       0;
$input-btn-focus-color:       rgba($component-active-bg, 1);
$input-btn-focus-box-shadow:  none;

$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-line-height-sm:    1.5;

$input-btn-padding-y-lg:      .7rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-line-height-lg:    1.5;

$input-font-size-sm:           .875rem;
$input-font-size:          1rem;
$input-font-size-lg:       1.25rem;

// Buttons

$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x + 0.25;
$btn-line-height:             $input-btn-line-height;

$btn-padding-y-sm:            $input-btn-padding-y-sm;
$btn-padding-x-sm:            $input-btn-padding-x-sm;
$btn-line-height-sm:          $input-btn-line-height-sm;

$btn-padding-y-lg:            $input-btn-padding-y-lg;
$btn-padding-x-lg:            $input-btn-padding-x-lg;
$btn-line-height-lg:          $input-btn-line-height-lg;

$btn-border-width:            $border-width;

$btn-font-size:               $font-size-base;
$btn-font-weight:             $font-weight-bold;
$btn-text-transform:          uppercase;
$btn-letter-spacing:          .025em;
$btn-box-shadow:              $box-shadow-sm;
$btn-hover-box-shadow:        $box-shadow-sm;
$btn-focus-box-shadow:        $btn-hover-box-shadow;
$btn-focus-width:             $border-width;


$btn-active-box-shadow:       none;


// Forms

$input-padding-y:                       .6rem;
$input-padding-x:                       .75rem;
$input-line-height:                     $input-btn-line-height;

$input-padding-y-sm:                    $input-btn-padding-y-sm;
$input-padding-x-sm:                    $input-btn-padding-x-sm;
$input-line-height-sm:                  $input-btn-line-height-sm;

$input-padding-y-lg:                    $input-btn-padding-y-lg;
$input-padding-x-lg:                    $input-btn-padding-x-lg;
$input-line-height-lg:                  $input-btn-line-height-lg;

$input-border-radius:                   $border-radius;
$input-border-radius-xl:                $border-radius-xl;
$input-border-radius-lg:                $border-radius-lg;
$input-border-radius-sm:                $border-radius-sm;

$input-disabled-bg:                     $gray-200;

$input-muted-bg:                        $gray-100;

$input-bg:                              $white;
$input-color:                           $gray-900;
$input-border-color:                    $gray-400;
$input-border-width:                    $border-width ;
$shadow-input:	                        $shadow-pixel;
$shadow-input-focus:                    .1rem .1rem 0 rgba($gray-200,.5);
$shadow-danger:                         .2rem .2rem 0 rgba($danger,.5);
$shadow-success:                        .2rem .2rem 0 rgba($success,.5);
$input-box-shadow:                      0 4px 30px rgba(0, 0, 0, 0.05);

$input-focus-bg:                        $white;
$input-focus-border-color:              theme-color("light");
$input-focus-color:                     $input-color;
$input-focus-width:                     0;
$input-focus-box-shadow:                none; //0 1px 3px 0 $gray-500;

$input-placeholder-color:               $gray-800;
$input-focus-placeholder-color:         $gray-800;

$input-height-border:                   $input-border-width;
$input-height-inner-half:               calc(#{$input-line-height * .325rem} + #{$input-padding-y});
$input-height:                          calc(#{$input-line-height * 1em} + #{$input-btn-padding-y-lg * 2});
$input-height-lg:                       calc(#{$input-line-height-lg * 1.2em} + #{$input-btn-padding-y-lg * 2});
$input-height-xl:                       calc(#{$input-line-height-lg * 1.5em} + #{$input-btn-padding-y-lg * 2} + #{$input-height-border});

$input-transition:                      all 0.3s ease-in-out;


// Input groups

$input-group-addon-color:               $input-placeholder-color;
$input-group-addon-bg:                  $input-bg;
$input-group-addon-border-color:        $input-border-color;

$input-group-addon-focus-color:               $input-focus-color;
$input-group-addon-focus-bg:                  $input-focus-bg;
$input-group-addon-focus-border-color:        $gray-200;

// Custom forms

$custom-control-gutter:                 1.75rem;
$custom-control-spacer-x:               1rem;
$custom-control-line-height:            1; 
$custom-control-indicator-size:         1.25rem;

$custom-control-bg:                     $gray-300;
$custom-control-indicator-bg:           $white;
$custom-control-border-width:           $border-width;
$custom-control-border-color:           $input-border-color;
$custom-control-box-shadow:             none;



$custom-control-indicator-hover-color:         $component-hover-color;
$custom-control-indicator-hover-bg:            $primary;
$custom-control-indicator-hover-border-color:  $component-hover-border-color;

$custom-control-indicator-active-color:         $component-active-color;
$custom-control-indicator-active-border-color:  $component-active-border-color;


$custom-control-indicator-checked-color:        $secondary;
$custom-control-indicator-checked-bg:           $secondary;
$custom-control-indicator-checked-border-color: $secondary;
$custom-control-indicator-checked-disabled-bg:  rgba(theme-color("primary"), .7);
$custom-control-indicator-border-width:         1px;

$custom-control-indicator-disabled-bg:          $gray-200;
$custom-control-label-disabled-color:           $gray-600;

$custom-checkbox-bg:                            $gray-300;
$custom-checkbox-checked-bg:                    theme-color("primary");
$custom-checkbox-disabled-checked-bg:           rgba(theme-color("primary"), .7); 
$custom-checkbox-indicator-border-radius:       $border-radius-md;
$custom-checkbox-indicator-border-width:        $border-width-md;
$custom-checkbox-indicator-icon-checked:        $font-awesome-5;
$square-checkbox-indicator-border-radius:       3px;

$custom-toggle-slider-bg:                       $primary;
$custom-toggle-slider-indicator-bg:             $white;
$custom-toggle-checked-bg:                      theme-color("secondary");
$custom-toggle-disabled-bg:                     $gray-200;
$custom-toggle-disabled-checked-bg:             rgba(theme-color("secondary"), .7);
$custom-toggle-border-radius:                   .8rem;

$custom-switch-indicator-size:                  calc(#{$custom-control-indicator-size} - #{$custom-control-indicator-border-width * 4});

// Form validation

$form-feedback-valid-bg:         lighten($success, 15%);
$form-feedback-valid-color:      theme-color("success");
$form-feedback-invalid-bg:       theme-color("danger");
$form-feedback-invalid-color:    theme-color("danger");


// Allows for customizing button radius independently from global border radius

$btn-border-radius:           $input-border-radius;
$btn-border-radius-xl:        $input-border-radius-xl;
$btn-border-radius-lg:        $input-border-radius-lg;
$btn-border-radius-sm:        $input-border-radius;


// No UI Slider

$noui-target-bg:                  $gray-100;
$noui-target-thickness:           5px;
$noui-target-border-radius:       5px;
$noui-target-border-color:        0;
$noui-target-box-shadow:          inset $box-shadow-sm;
$noui-box-shadow:                 $box-shadow-sm;         
$noui-slider-connect-bg:          $primary;
$noui-slider-connect-disabled-bg: $gray-100;
$noui-handle-width:               15px;
$noui-handle-bg:                  theme-color("primary");
$noui-handle-border:              0;
$noui-handle-border-radius:       $border-radius;
$noui-origin-border-radius:       $circle-radius;


// Dropdown

$dropdown-padding-y:                .7rem;
$dropdown-padding-x:                .7rem;
$dropdown-font-size:                $font-size-sm;
$dropdown-bg:                       $white;
$dropdown-border-width:             0;
$dropdown-border-color:             rgba($black, .15);
$dropdown-border-radius:            $border-radius-md;
$dropdown-box-shadow:               $box-shadow-lg;
$dropdown-item-color:               $gray-700;
$dropdown-item-font-weight:         $font-weight-light;
$dropdown-item-hover-color:         $gray-900;
$dropdown-header-color:             $gray-900;
$dropdown-header-font-weight:       $font-weight-bold;

$dropdown-link-active-color:        $white;
$dropdown-link-active-bg:           $gray-400;

// Navs

$nav-link-padding-y:                .8rem;
$nav-link-padding-x:                .75rem;
$nav-link-color:                    $gray;
$nav-link-hover-color:              $primary;
$nav-link-active-color:             $gray-700;
$nav-link-shadow:                   0 4px 30px rgba(0, 0, 0, 0.05);

$nav-pills-padding-y:               .75rem;
$nav-pills-padding-x:               .85rem;

$nav-pills-space-x:                 1rem;
$nav-pills-border-circle:           50%;

$nav-pills-bg:                      $white;
$nav-pills-border-width:            1px;
$nav-pills-border-color:            $gray-300;
$nav-pills-border-radius:           $border-radius;

$nav-pills-link-hover-color:        $primary;
$nav-pills-link-active-color:       $primary;
$nav-pills-active-border-color:     $gray-200;
$nav-pills-link-active-bg:          $white;

$nav-divider-color:                 $light;

// Progress Bars
$progress-xl-size: .8rem;
$progress-lg-size: .6rem;
$progress-sm-size: .2rem;

// Navbar
$navbar-transition:                 cubic-bezier(0.34, 1.45, 0.7, 1);
$navbar-padding-y:                  1rem;
$navbar-padding-x:                  1rem;

$navbar-nav-link-padding-x:         1rem;
$navbar-nav-link-padding-y:         1rem;

$navbar-nav-link-font-size:         1rem;
$navbar-nav-link-font-weight:       400;
$navbar-nav-link-text-transform:    normal;
$navbar-nav-link-letter-spacing:    0;
$navbar-nav-link-border-radius:     $border-radius;

$navbar-dark-bg:                    transparent;
$navbar-dark-hover-bg:              rgba(255, 255, 255, .1);
$navbar-dark-active-bg:             rgba(255, 255, 255, .1);
$navbar-dark-color:                 rgba($white, .9);
$navbar-dark-hover-color:           $white;
$navbar-dark-active-color:          $white;
$navbar-dark-disabled-color:        rgba($white, .5);
$navbar-dark-toggler-border-color:  transparent;

$navbar-light-bg:                   transparent;
$navbar-light-hover-bg:             rgba(0, 0, 0, .1);
$navbar-light-active-bg:            rgba(0, 0, 0, .1);
$navbar-light-color:                $gray-700;
$navbar-light-hover-color:          $gray-800;
$navbar-light-active-color:         rgba($gray-800, .8);
$navbar-light-disabled-color:       rgba($gray-800, .3);
$navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$navbar-light-toggler-border-color: transparent;

$navbar-dropdown-item-font-weight:  $font-weight-normal;
$navbar-dropdown-item-font-size:    1rem;

// Sidebar
$navbar-dark-border-color:                 rgba(255, 255, 255, .1) !default;

//Avatars
$user-avatar-height:2.5rem;  
$user-avatar-width: 2.5rem;

$user-avatar-height-xs:1.5rem;  
$user-avatar-width-xs: 1.5rem;

$user-avatar-height-lg:3.5rem;
$user-avatar-width-lg:3.5rem;

$user-avatar-height-xl:4.5rem;
$user-avatar-width-xl:4.5rem;

// Nav tabs

$nav-tabs-border-color:             $gray-200;
$nav-tabs-link-active-color:        $primary;
$nav-tabs-link-active-bg:           $gray-100;


// Alerts

$alert-text-color: $white;
$alert-padding-y: 1rem;
$alert-padding-x: 1.5rem;
$alert-border-radius: $border-radius;

$alert-bg-level: -1;
$alert-border-level: 1;
$alert-color-level: 2;
$alert-link-color:$white;


// List group

$list-group-bg:                     $white;
$list-group-border-color:           $border-color; //rgba($black, .125);
$list-group-border-width:           $border-width;
$list-group-border-radius:          $border-radius;

$list-group-item-padding-y:         1rem;
$list-group-item-padding-x:         1rem;

$list-group-hover-bg:               $gray-100;
$list-group-active-color:           $white;
$list-group-active-bg:              $secondary;
$list-group-active-border-color:    $list-group-active-bg;

$list-group-disabled-color:         $gray-600;
$list-group-disabled-bg:            $list-group-bg;

$list-group-action-color:           $gray-700;
$list-group-action-hover-color:     $list-group-action-color;

$list-group-action-active-color:    $list-group-action-color;
$list-group-action-active-bg:       $gray-200;


// Close

$close-font-size:                   $font-size-base * 1.5;
$close-font-weight:                 $font-weight-bold;
$close-bg:                          transparent;
$close-hover-bg:                    transparent;
$close-color:                       rgba(0, 0, 0, .6);
$close-hover-color:                 rgba(0, 0, 0, .9);
$close-text-shadow:                 none;


// Popovers

$popover-font-size:                 $font-size-sm;
$popover-bg:                        $white;
$popover-max-width:                 276px;
$popover-border-width:              1px;
$popover-border-color:              rgba($black, .05);
$popover-border-radius:             $border-radius-lg;
$popover-box-shadow:                0px .5rem 2rem 0px rgba($black, .2);

$popover-header-bg:                 $popover-bg;
$popover-header-color:              $headings-color;
$popover-header-padding-y:          .75rem;
$popover-header-padding-x:          .75rem;

$popover-body-color:                $body-color;
$popover-body-padding-y:            $popover-header-padding-y;
$popover-body-padding-x:            $popover-header-padding-x;

$popover-arrow-width:               1.5rem;
$popover-arrow-height:              .75rem;
$popover-arrow-color:               $popover-bg;

$popover-arrow-outer-color:         transparent;


// Badges

$badge-font-size:                   66%;
$badge-font-weight:                 $font-weight-normal;
$badge-padding-y:                   .1rem;
$badge-padding-x:                   .4rem;


$badge-pill-padding-x:              .875em;
$badge-pill-border-radius:          10rem;

$badge-circle-size:                 2rem;

// Pagination

// $pagination-padding-y:              .5rem;
// $pagination-padding-x:              .75rem;
// $pagination-padding-y-sm:           .25rem;
// $pagination-padding-x-sm:           .5rem;
// $pagination-padding-y-lg:           .75rem;
// $pagination-padding-x-lg:           1.5rem;
// $pagination-line-height:            1.25;

$pagination-color:                  $gray-800;
$pagination-bg:                     $white;
$pagination-border-width:           $border-width;
$pagination-border-color:           $gray-400;


$pagination-hover-color:            $primary;
$pagination-hover-bg:               $gray-300;
$pagination-hover-border-color:     $gray-300;

$pagination-active-color:           $component-active-color;
$pagination-active-bg:              $primary;
$pagination-active-border-color:    $pagination-active-bg;
$pagination-active-box-shadow:      $btn-hover-box-shadow;

$pagination-disabled-color:         $gray-600;
$pagination-disabled-bg:            $white;
$pagination-disabled-border-color:  $gray-300;


// Cards

$card-spacer-y:                     1.25rem;
$card-spacer-x:                     1.5rem;
$card-social-padding:               .25rem .375rem; 
$card-border-width:                 $border-width;
$card-border-radius:                $border-radius;
$card-border-color:                 rgba($gray-100, .05);
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg:                       $gray-100;
$card-bg:                           $white;
$card-opacity:                      1;

$card-img-overlay-padding:          1.25rem;
$transition-bezier-card:            cubic-bezier(0.34, 1.45, 0.7, 1);

//$card-group-margin:                 ($grid-gutter-width / 2);
//$card-deck-margin:                  $card-group-margin;

$card-columns-count:                3;
$card-columns-gap:                  1.25rem;
$card-columns-margin:               $card-spacer-y;

//Timelines
$timeline-font-size:                $font-size-xxl;
$timeline-font-color:               $gray-800;
$timeline-border-color:             $gray-300;
$timeline-background-color:         $gray-100;
$timeline-line-height:              3;
$timeline-border-width:             2px;
$timeline-circle-radius:            50%;
$timeline-spacer-y:                 1.25rem;
$timeline-spacer-x:                 2.75rem;


// Steps
$step-number-size:                  12rem;
$step-shape-size:                   7rem;
$step-border-width:                 $border-width-md;
$step-icon-size:                    $icon-size-xl; 

// Tooltips
$tooltip-font-size:                 $font-size-sm;
$tooltip-color:                     $black;
$tooltip-bg:                        $white;

// Modals

$modal-inner-padding:               1.5rem;

$modal-lg:                          800px;
$modal-md:                          500px;
$modal-sm:                          380px;

$modal-title-line-height:           1.1;

//$modal-title-line-height:           $shape-height-base;

$modal-content-bg:               $white;
$modal-content-border-color:     rgba($black, .2);
$modal-content-border-width:     1px;
$modal-content-border-radius:   $border-radius-lg;

$modal-backdrop-bg:           $black;
$modal-backdrop-opacity:      .16;
$modal-header-border-color:   $gray-200;
$modal-footer-border-color:   $modal-header-border-color;
$modal-header-border-width:   $modal-content-border-width;
$modal-footer-border-width:   $modal-header-border-width;
$modal-header-padding:        1.25rem;

// Datepicker

$datepicker-border-radius: $border-radius-sm;
$datepicker-dropdown-padding: 20px 22px;

$datepicker-cell-transition: $transition-base;
$datepicker-cell-hover-background: lighten($gray-400, 55%);
$datepicker-cell-border-radius: 50%;
$datepicker-cell-width: 36px;
$datepicker-cell-height: 36px;

$datepicker-disabled-cell-color: $gray-300;
$datepicker-disabled-old-new-color: $gray-500;

$datepicker-header-cell-border-radius: $border-radius-sm;

$datepicker-active-color: $white;
$datepicker-active-background: theme-color("secondary");
$datepicker-active-box-shadow: none;

$datepicker-range-background: theme-color("secondary");
$datepicker-range-cell-focused-background: darken($datepicker-range-background, 5%);
$datepicker-range-color: $white;
$datepicker-range-highlighted-bg: $gray-200;

$datepicker-dropdown-border: lighten($gray-400, 40%);
$datepicker-dropdown-bg: $white;
$datepicker-highlighted-bg: $datepicker-active-background;


// Footer

$footer-link-font-size: .85rem;
$footer-bg: theme-color("secondary");
$footer-color: $gray-600;
$footer-link-color: $gray-600;
$footer-link-hover-color: $gray-700;
$footer-heading-color: $gray-600;
$footer-heading-font-size: $font-size-sm;
$padding-footer-sm:       .125rem ;
$padding-footer:          .375rem ;
$padding-footer-md:       .625rem ;
$padding-footer-general:  .9375rem;
$padding-footer-big:      1.5rem;

// Posts
$avatar-sm-y: 1.5rem;
$avatar-sm-x: 1.5rem;

$avatar-md-y: 2rem;
$avatar-md-x: 2rem;

$avatar-lg-y: 3rem;
$avatar-lg-x: 3rem;

